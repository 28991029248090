.checklist {
  .list-group-item {
    border-radius: 0 !important;
    padding-top: 4px;
    padding-bottom: 4px; }

  .list-group-item, .list-group-item+.list-group-item {
    border-top-width: 1px;
    margin-top: -1px; } }

button.checklist-button, button.checklist-button:hover {
  padding: 2px 4px;
  text-decoration: none; }

button.item-name {
  padding: 2px 4px;
  margin-right: 4px;
  margin-left: 4px;

  &:not(:hover) {
    color: black;
    text-decoration: none; } }

.adder {
  &.border {
    border-color: transparent !important; }

  .add-item {
    padding: 2px 4px;
    color: black;
    text-decoration: none; } }

input.adder-name {
  padding: 2px 4px;
  margin-right: 8px;
  margin-left: 4px;
  border-color: transparent !important; }

button.adder-button, button.delete-button {
  padding: 2px 8px; }

.dropdown-item:active {
  background-color: initial; }
